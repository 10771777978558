<template>
  <div class="content">
    <div class="container" v-show="!showRiderOrderDetail">
        <SelectStore @selectStore="handleSelectStore"/>
        <div class="right-container">
          <el-tabs v-model="activeTab" type="border-card" @tab-click="handleTabClick">
            <el-tab-pane label="骑手运效" name="rider-efficiency-list" key="rider-efficiency-list">
              <RiderEfficiencyList ref="riderEfficiencyList" :selectedStore="selectedStore" @open="handleOpenDetail"/>
            </el-tab-pane>
            <el-tab-pane label="自取货骑手运效" name="self-pickup-goods-rider-efficiency-list" key="self-pickup-goods-rider-efficiency-list">
              <SelfPickupGoodsRiderEfficiencyList ref="selfPickupGoodsRiderEfficiencyList" :selectedStore="selectedStore" @open="handleOpenDetail"/>
            </el-tab-pane>
            <el-tab-pane label="自配送骑手运效" name="self-delivery-rider-efficiency-list" key="self-delivery-rider-efficiency-list">
              <SelfDeliveryRiderEfficiencyList ref="selfDeliveryRiderEfficiencyList" :selectedStore="selectedStore" @open="handleOpenDetail"/>
            </el-tab-pane>
          </el-tabs>
        </div>
    </div>
    <riderOrderDetail v-if="showRiderOrderDetail" @close="handleCloseDetail" :riderData="riderData"/>
  </div>
</template>

<script>
import SelectStore from "@/views/components/SelectStore";
import RiderEfficiencyList from "@/views/components/DeliveryEffiRider/RiderEfficiencyList";
import SelfPickupGoodsRiderEfficiencyList from "@/views/components/DeliveryEffiRider/SelfPickupGoodsRiderEfficiencyList";
import SelfDeliveryRiderEfficiencyList from "@/views/components/DeliveryEffiRider/SelfDeliveryRiderEfficiencyList";
import riderOrderDetail from "@/views/components/DeliveryEffiRider/riderOrderDetail";

export default {
  name: "deliveryEffiRider",
  components: {SelectStore,RiderEfficiencyList,SelfPickupGoodsRiderEfficiencyList,SelfDeliveryRiderEfficiencyList,riderOrderDetail},
  data() {
    return {
      height: document.documentElement.clientHeight - 142 + "px",
      selectedStore:[],
      activeTab: 'rider-efficiency-list',
      riderData:null,
      showRiderOrderDetail:false,
    };
  },
  created() {

  },
  mounted() {

  },
  methods: {
    handleSelectStore(e) {
      this.selectedStore = e;
    },
    handleTabClick(tab) {
      if (tab.name === 'rider-efficiency-list' && this.$refs.riderEfficiencyList) {
        this.$refs.riderEfficiencyList.search();
      } else if (tab.name === 'self-pickup-goods-rider-efficiency-list' && this.$refs.selfPickupGoodsRiderEfficiencyList) {
        this.$refs.selfPickupGoodsRiderEfficiencyList.search();
      } else if (tab.name === 'self-delivery-rider-efficiency-list' && this.$refs.selfDeliveryRiderEfficiencyList) {
        this.$refs.selfDeliveryRiderEfficiencyList.search();
      }
    },
    handleOpenDetail(e){
      this.showRiderOrderDetail = true;
      this.riderData = e;
    },
    handleCloseDetail(){
      this.showRiderOrderDetail = false;
    }
  },
};
</script>

<style lang="scss" scoped>
.content{
  width: 100%;
  height: 100%;
}
.container{
  width: 100%;
  height: 100%;
  background-color: #fff;
  padding: 20px;
}
.right-container{
  float:left;
  width:calc(100% - 240px);
  height: 100%;
}
.list-container{
  width: 100%;
  .toolbar{
    width: 100%;
    height: 50px;
  }
}
::v-deep .el-tabs__item{
  height: 50px;
  line-height: 50px;
  font-size: 16px;
}
</style>
