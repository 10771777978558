<template>
  <div class="list-container" :style="{height: height}">
    <div class="toolbar">
      <el-input placeholder="查询关键字" v-model="keyword" :clearable="true" :style="{ width:'250px',float: 'right'}">
        <el-button slot="append" icon="el-icon-search" @click="search"></el-button>
      </el-input>
    </div>
    <el-table :data="ordFreightAnalyseRiderList" highlight-current-row ref="table" v-loading="loading" :max-height="tableMaxHeight">
      <el-table-column label="序号" type="index" align="center" width="60px" :index="getIndex"></el-table-column>
      <el-table-column label="门店名称" align="center" width="140px" prop="sStore"></el-table-column>
      <el-table-column label="姓名" align="center" width="100px" prop="sRider"></el-table-column>
      <el-table-column label="特殊人群" align="center" width="60px" >
        <template #default="scope">
          {{ getSpecial(scope.row.bSpecial) }}
        </template>
      </el-table-column>
      <el-table-column label="接单量" align="center" width="60px" prop="totalReceive"></el-table-column>
      <el-table-column label="配送量" align="center" width="60px" prop="totalDelivery"></el-table-column>
      <el-table-column label="取消量" align="center" width="60px" prop="totalCancel"></el-table-column>
      <el-table-column label="接单率" align="center" width="60px" prop="receiveRate" :formatter="rateFormatter"></el-table-column>
      <el-table-column label="平均接单时间" align="center" width="120px" prop="avgReceiveTime" :formatter="timeFormatter"></el-table-column>
      <el-table-column label="运费合计" align="center" width="100px" prop="totalDeliveryCost" :formatter="priceFormatter"></el-table-column>
      <el-table-column label="小费合计" align="center" width="100px" prop="totalDispatchCost" :formatter="priceFormatter"></el-table-column>
      <el-table-column label="运费总计" align="center" width="100px" prop="totalCost" :formatter="priceFormatter"></el-table-column>
      <el-table-column label="平均配送距离" align="center" width="120px" prop="avgDistance"></el-table-column>
      <el-table-column label="平均时长" align="center" width="100px" prop="avgDeliveryTime" :formatter="timeFormatter"></el-table-column>
      <el-table-column label="平均运费" align="center" width="100px" prop="avgDeliveryCost" :formatter="priceFormatter"></el-table-column>
      <el-table-column label="评价" align="center" width="80px" prop="totalAppraise"></el-table-column>
      <el-table-column label="准时率" align="center" width="80px" prop="punctualityRate" :formatter="rateFormatter"></el-table-column>
      <el-table-column fixed="right" align="center" label="操作">
        <template slot-scope="scope">
          <el-button type="text" @click="view(scope.row)">详情</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :layout="pageLayout"
      :total="total"
      :current-page="pageNum"
      :page-sizes="pageSelect"
      :page-size="pageSize"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :style="{marginTop: '15px'}">
    </el-pagination>
  </div>
</template>

<script>
import {getOrdFreightAnalyseRiderSelfList} from "@/api/delivery";
import {specialMap} from "@/utils/deliveryMap";
import {formatSeconds, formatTime} from "@/utils/utils";

export default {
  name: "SelfPickupGoodsRiderEfficiencyList",
  components: {},
  props: {
    selectedStore: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      height: document.documentElement.clientHeight - 142 + "px",
      tableMaxHeight: document.documentElement.clientHeight - 260,
      currentSid:'',
      keyword:'',
      rStores:[],
      ordFreightAnalyseRiderList:[],
      loading:false,
      total: 0, //总条数
      pageNum: 1, //当前页
      pageSize: 10, //每页显示条数
      pageSelect: [10, 20, 30, 50],
      pageLayout:"prev, pager, next,jumper,total,sizes",
      showViewRiderDetail: false,
    };
  },
  created() {
  },
  mounted() {
  },
  computed: {
    getSpecial() {
      return function(bStatus) {
        return specialMap[bStatus] || '未知';
      };
    },
  },
  methods: {
    getOrdFreightAnalyseRiderSelfList() {
      let params = {
        search:this.keyword,
        rStores:this.rStores,
        pageNum:this.pageNum,
        pageSize:this.pageSize,
      }
      this.loading = true;
      getOrdFreightAnalyseRiderSelfList(params).then(result => {
        if (result) {
          this.ordFreightAnalyseRiderList = result.rows;
          this.total = result.total;
        }
      }).finally(() => {
        this.loading = false;
      });
    },
    // 查询
    search(){
      this.pageNum = 1;
      this.getOrdFreightAnalyseRiderSelfList();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.pageNum = 1;
      this.getOrdFreightAnalyseRiderSelfList();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getOrdFreightAnalyseRiderSelfList();
    },
    view(item){
      this.$emit('open',{sid:item.rRider,cName:item.sRider});
    },
    getIndex(rowIndex) {
      return (this.pageNum - 1) * this.pageSize + rowIndex +1;
    },
    priceFormatter(row, column, cellValue) {
      return cellValue?'￥'+cellValue:'';
    },
    rateFormatter(row, column, cellValue) {
      return cellValue?cellValue*100+'%':'';
    },
    timeFormatter(row, column, cellValue) {
      return formatSeconds(cellValue);
    },
    dateFormatter(row, column, cellValue) {
      return cellValue?formatTime(cellValue,"YYYY-MM-DD HH:mm"):'';
    },
  },
  watch: {
    selectedStore(newVal) {
      this.rStores = newVal;
      this.pageNum = 1;
      this.getOrdFreightAnalyseRiderSelfList();
    },
  },
};
</script>

<style lang="scss" scoped>
.list-container{
  width: 100%;
  .toolbar{
    width: 100%;
    height: 50px;
  }
}
</style>
